import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "~/components/layout"
import SEO from "~/components/seo"
import HeroSection from "~/components/sections/board/hero"

const Board: React.FC<PageProps> = () => (
  <Layout>
    <SEO title="Executive Board" />
    <HeroSection />
  </Layout>
)

export default Board
