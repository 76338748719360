import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import LinkedInWhite from "~/images/ln_white.svg"
import Link from "~/components/link"
import useBoardMembers from "~/hooks/cms/useBoardMembers"
import { RichText } from "prismic-reactjs"

const HeroSection: React.FC = () => {
  const [isAnimated, setIsAnimated] = useState(false)
  const boardMembers = useBoardMembers()

  useEffect(() => {
    setIsAnimated(true)
  }, [setIsAnimated])

  return (
    <section className={styles.root}>
      <div
        className={classNames(styles.animation, {
          [styles.animation__animated]: isAnimated,
        })}
      ></div>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h1 className={classNames("headline--decorated", styles.headline)}>
            Executive <span>Board</span>
          </h1>
          <div className={styles.items}>
            {boardMembers.map(({ node }) => {
              const hasBio = node.data.bio.raw.length > 0
              return (
                <div
                  key={node.data.name.text}
                  className={styles.item__wrapper}
                  tabIndex={0}
                  aria-haspopup={true}
                >
                  <div
                    className={classNames(styles.item, {
                      [styles.item__hasBio]: hasBio,
                    })}
                  >
                    <div>
                      <GatsbyImage
                        alt={node.data.picture.localFile.alt || ""}
                        image={
                          node.data.picture.localFile.childImageSharp
                            .gatsbyImageData
                        }
                        objectFit="cover"
                        objectPosition="50% 50%"
                      />
                    </div>
                    <div className={styles.info}>
                      <h2 className={styles.name}>{node.data.name.text}</h2>
                      <p className={styles.position}>
                        {node.data.position.text}
                      </p>
                    </div>
                  </div>
                  {hasBio && (
                    <div className={styles.tooltip}>
                      <RichText render={node.data.bio.raw} />
                      <Link
                        ariaLabel="Link to LinkedIn"
                        to={node.data.link_to_linkedin.url}
                      >
                        <LinkedInWhite />
                      </Link>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroSection
