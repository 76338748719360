// extracted by mini-css-extract-plugin
export var animation = "index-module--animation--5a610";
export var animation__animated = "index-module--animation__animated--db9c9";
export var content = "index-module--content--94c09";
export var headline = "index-module--headline--77101";
export var info = "index-module--info--3d12c";
export var item = "index-module--item--31de2";
export var item__hasBio = "index-module--item__hasBio--c849d";
export var item__wrapper = "index-module--item__wrapper--cb38b";
export var items = "index-module--items--d70ff";
export var name = "index-module--name--ca352";
export var position = "index-module--position--69754";
export var root = "index-module--root--e6bd2";
export var tooltip = "index-module--tooltip--ae10c";
export var wrapper = "index-module--wrapper--9c0ed";