import { graphql, useStaticQuery } from "gatsby"

const useBoardMembers = () => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicBoardMember(
        sort: { fields: first_publication_date, order: ASC }
      ) {
        edges {
          node {
            data {
              bio {
                raw
              }
              link_to_linkedin {
                url
              }
              name {
                text
              }
              picture {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
                alt
              }
              position {
                text
              }
            }
          }
        }
      }
    }
  `)

  return data.allPrismicBoardMember.edges
}

export default useBoardMembers
